import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Slider from 'react-slick'
import { Typography } from 'src/components/atoms/Typography'
import { INSTRUMENT } from 'src/config/enums'
import { SEE_MORE_URLS } from 'src/config/constants/landing'
import { CourseCard } from './CourseCard'
import { useCourses } from '../../hooks/useCourses'
import { useSelector } from '../../store/types'

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: '70px 30px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textAlign: 'center',
  },
  title: {
    fontWeight: 'bolder',
  },
  subtitle: {
    fontWeight: 'bolder',
    color: theme.palette.primary.main,
  },
  slider: {
    height: '100%',
    padding: 0,
    width: ({ slidesToShow }: { slidesToShow: number }) => {
      switch (slidesToShow) {
        case 2: return '50%'
        case 3: return '75%'
        default: return '10%'
      }
    },
    margin: '50px 0',
  },
}))

const settings = (slidesToShow) => ({
  dots: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow,
  responsive: [
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
})

export const CoursesSection = () => {
  const isPublic = useSelector(({ general }) => general.isPublic)
  const courses = useCourses(isPublic)

  let slidesToShow = 4
  slidesToShow = courses?.length === 2 ? 2 : slidesToShow
  slidesToShow = courses?.length === 3 ? 3 : slidesToShow
  const classes = useStyles({ slidesToShow })

  const coursesMapped = courses?.map((course) => {
    if (course.instrument.slug === INSTRUMENT.ACORDEON) course.seeMoreUrl = SEE_MORE_URLS.acordeon
    if (course.instrument.slug === INSTRUMENT.GUITARRA) course.seeMoreUrl = SEE_MORE_URLS.guitarra
    if (course.instrument.slug === INSTRUMENT.PERCUSION_VALLENATA) course.seeMoreUrl = SEE_MORE_URLS.percusion
    course.href = `cursos/${course.instrument.slug}`
    return course
  })

  const specialCourses = coursesMapped
    ?.flatMap((course) => course.levels
      .map((level) => {
        level.href = `cursos/${course.instrument.slug}/${level.slug}`
        if (level.slug === INSTRUMENT.GUITARRA_VALLENATA) level.seeMoreUrl = SEE_MORE_URLS.guitarraVallenata
        if (level.slug === INSTRUMENT.GUITARRA_URBANA) level.seeMoreUrl = SEE_MORE_URLS.guitarraUrbana
        if (level.slug === INSTRUMENT.PERCUSION_VALLENATA_GUACHARACA) level.seeMoreUrl = SEE_MORE_URLS.percusionGuacharaca
        return level
      }).filter((level) => level.isSpecial || level.slug === INSTRUMENT.PERCUSION_VALLENATA_GUACHARACA))

  const coursesList = coursesMapped && specialCourses ? [...coursesMapped, ...specialCourses] : []

  return (
    <Grid spacing={0} container flexDirection="column" justifyContent="center" alignItems="center" classes={{ root: classes.root }}>
      <Typography variant="h3" classes={{ root: classes.title }}>Cursos</Typography>
      <Typography variant="h4" classes={{ root: classes.subtitle }}>¿Qué quieres aprender?</Typography>

      <Slider {...settings(slidesToShow)} className={classes.slider} arrows>
        {
          coursesList?.map((course) => (
            <CourseCard
              key={course.slug}
              href={course.href}
              name={course.name}
              image={course.imageUrl}
              seeMoreUrl={course?.seeMoreUrl}
            />
          ))
        }

      </Slider>
    </Grid>
  )
}
