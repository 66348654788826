import Axios from 'src/services/Axios'
import { IInstrument } from './Instrument'
import { ILevel } from './Level/interfaces'
import Uris from './Uris'

export interface ICourse {
  created: Date
  description: string
  imageUrl: string
  instrument: IInstrument
  isActive: boolean
  levels: ILevel[]
  modified: Date
  name: string
  slug: string
  purchaseAmount: string
  seeMoreUrl?: string
  href?: string
}

export interface IFinishedCourse {
  course: string
  level: string
  open: boolean
}

export const load = async (args): Promise<ICourse[]> => {
  const { isPublic } = args.queryKey[1]
  const uri = !isPublic ? Uris.courses : Uris.coursesPublic
  const { results } = await Axios.get(uri)
  return results
}
