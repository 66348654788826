import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FC } from 'react'
import { Typography } from 'src/components/atoms/Typography'
import { Image } from 'src/components/atoms/Image'

const useStyles = makeStyles((theme) => ({
  benefit: {
    width: '100%',
  },
  containerImage: {
    width: '100%',
    '& span': {
      padding: '60px 0 0 !important',
    },
  },
  benefitImg: {
    width: '100px !important',
    height: '100px !important',
    minWidth: '0 !important',
    minHeight: '0 !important',
  },
  text: {
    textAlign: 'center',
    fontSize: 'calc(.8em + 1vw)',
    fontWeight: '600',
  },
  [theme.breakpoints.down('md')]: {
    benefit: {
      padding: 20,
      display: 'flex',
      alignItems: 'center',
    },
    containerImage: {
      width: '100%',
      heigth: 'auto',
    },
    benefitImg: {
      width: '100%',
      height: 'auto',
    },
    text: {
      textAlign: 'left',
      marginLeft: 20,
    },
  },
}))

interface IProps {
  image: string
  alt: string
  texts: string[]
}

export const Benefit: FC<IProps> = ({ image, alt, texts }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      classes={{ root: classes.benefit }}
    >
      <Grid item className={classes.containerImage} xl={12} lg={12} md={12} sm={4} xs={5}>
        <Image
          className={classes.benefitImg}
          src={image}
          alt={alt}
        />
      </Grid>
      <Grid item container direction="column" xl={12} lg={12} md={12} sm={4} xs={6}>
        {
          texts.map((text) => (
            <Grid item key={text}>
              <Typography variant="h5" classes={{ root: classes.text }}>{text}</Typography>
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  )
}
