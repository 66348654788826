import Axios from './Axios'
import { ICountry } from './Country'

type Subject = 'platform' | 'courses' | 'tutorials' | 'other'

export interface PRQRequest {
  message: string
  subject: Subject
}

export interface ContactInfoRequest {
  name: string | null
  email: string | null
  country: ICountry | null
  phone_number: string | null
  message: string | null
}

const uri = '/api/pqrs/'
const uriContact = '/api/contact/send/'

export const sendPQR = async (pqrRequest: PRQRequest): Promise<{ message: string }> =>
  Axios.post(uri, pqrRequest)

export const sendContactInfo = async (contactInfo: ContactInfoRequest) =>
  Axios.post(uriContact, contactInfo)
