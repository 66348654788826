import React, { FC } from 'react'
import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'left',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
}))

interface InputErrorMessageProps {
    message: string
}

const InputErrorMessage: FC<InputErrorMessageProps> = ({ message }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography component="span" className={classes.errorMessage}>{message}</Typography>
    </div>
  )
}

export default InputErrorMessage
