import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Link from 'next/link'
import { FC } from 'react'
import { Typography } from 'src/components/atoms/Typography'

interface Props {
  title?: string
  textscreen?: string[]
  newstyle?: boolean
  href?: string
  image?: string
  positiontext?: string
}

const useStyles = makeStyles((theme) => ({
  home: {
    textAlign: 'right',
    backgroundImage: ({ image }: any) => (image ? `url('${image}')` : 'url("/images/landing/escuela-de-musica-online.jpg")'),
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    marginTop: ({ image }: any) => (image ? '50px' : '0px'),
    width: '100vw',
    maxWidth: '100%',
    height: '100vh',
    color: '#FFF',
    padding: '10vw',
  },
  title: {
    fontSize: 'calc(2.5em + 1vw)',
    fontWeight: 700,
    margin: 0,
    padding: 0,
  },
  subtitle: {
    display: 'inline-block',
    fontSize: 'calc(.8em + 1vw)',
    marginBottom: '20px',
    fontWeight: 'bold',
    textAlign: 'right',
    [theme.breakpoints.only('xs')]: {
      fontSize: 'calc(1.3em + 1vw)',
      marginBottom: '8px',
    },
  },
  text: {
    fontSize: 'calc(.8em + 1vw)',
    fontWeight: 600,
    margin: '-2px 0',
    padding: 0,
  },
  seeCoursesButton: {
    textDecoration: 'none',
    color: '#FFFFFF',
    display: 'flex',
    justifySelf: 'flex-end',
    backgroundColor: '#FFA304',
    fontSize: 'calc(1em + 1vw)',
    fontWeight: 600,
    marginTop: '30px',
    padding: '6px 30px',
    borderRadius: '10px',
    cursor: 'pointer',
    transitionDuration: '.3s',
    '&:hover': {
      background: '#f6a71c',
    },
  },
}))

const textDefault = ['Conoce nuestra plataforma interactiva', 'Aprende en casa y cuando quieras', 'Toca con amigos y disfruta']

export const HomeSection: FC<Props> = ({ image, title, newstyle, textscreen, positiontext = 'end', href = '/cursos' }) => {
  const classes = useStyles({ image, newstyle })
  return (
    <Grid id="home" component="section" container classes={{ root: classes.home }} justifyContent="center" flexDirection="column" alignItems={`flex-${positiontext}`}>
      <Grid item>
        <Typography variant="h1" classes={{ root: classes.title }}>{title || 'Escuela de Música Online'}</Typography>
        {
          newstyle &&
          <Typography variant="body1" classes={{ root: classes.subtitle }}>Tutor del curso</Typography>
        }
      </Grid>
      {
        textscreen ?
          textscreen.map((paragraph, i) => (
            <Grid item key={i}>
              <Typography variant="body1" classes={{ root: classes.text }}>{paragraph}</Typography>
            </Grid>
          )) :
          textDefault.map((paragraph, i) => (
            <Grid item key={i}>
              <Typography variant="body1" classes={{ root: classes.text }}>{paragraph}</Typography>
            </Grid>
          ))

      }
      <Grid item>
        <Link href={href} passHref>
          <Typography component="a" className={classes.seeCoursesButton}>Ver cursos</Typography>
        </Link>
      </Grid>
    </Grid>
  )
}
