import { makeStyles } from '@mui/styles'
import { useGetAllCountries } from 'src/hooks/useCommons'
import { Button, Typography } from '@mui/material'
import * as ContactService from 'src/services/Contact'
import { Form, Formik } from 'formik'
import { success } from 'src/utils/toast'
import clsx from 'clsx'
import { object, string } from 'yup'
import { phoneRegExp } from 'src/utils/phoneNumber'
import { ICountry } from 'src/services/Country'
import InputErrorMessage from '../atoms/InputErrorMessage/InputErrorMessage'
import PhoneInput from '../molecules/PhoneInput'
import InputText from '../atoms/InputText/InputText'
import InputAutoComplete from '../molecules/InputAutoComplete'

const validationSchema = object({
  name: string()
    .required('El Nombre es requerido'),
  email: string()
    .required('El correo es requerido')
    .email('Por favor ingrese un correo valido'),
  phone_number: string()
    .required('El numero de teléfono es requerido')
    .min(6, 'Debe tener minimo 6 digitos')
    .max(12, 'Debe tener maximo 12 digitos')
    .matches(phoneRegExp, 'El teléfono no es valido'),
  country: string()
    .required('El país es requerido')
    .nullable(),
  message: string()
    .required('El mensaje es requerido'),
})

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '50px',
    textAlign: 'center',
    borderRadius: '20px',
    backgroundColor: 'rgba(45, 45, 45, 0.95)',
    padding: 20,
    color: '#FFFFFF',
    maxWidth: '400px',
    position: 'relative',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 30,
      top: -30,
      backgroundColor: '#C53939',
      width: 15,
      height: 70,
      [theme.breakpoints.down('xs')]: {
        height: 50,
      },
    },
  },
  title: {
    fontSize: 'calc(2.5em + 1vw)',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  subtitle: {
    fontSize: 'calc(.7em + 1vw)',
    fontWeight: 'bold',
  },
  info: {
    marginTop: 10,
    fontSize: 'calc(.4em + 1vw)',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      fontSize: 'calc(.5em + 1vw)',
    },
    '& a': {
      textDecoration: 'none',
      color: '#FFFFFF',
    },
    '& div, & a': {
      padding: '2px 0',

      '& i': {
        marginRight: 10,
        fontWeight: 'bold',
      },

    },

  },

  form: {
    display: 'grid',
    gridGap: 10,
    padding: '0 10px',
    justifyContent: 'center',
    gridTemplateColumns: '50% 50%',
    margin: '20px 0',

    [theme.breakpoints.down('xs')]: {
      gridTemplateRows: '1fr 1fr 1fr 1fr 4fr',
      gridTemplateColumns: '100%',
      gridAutoFlow: 'column',
    },
  },

  formControl: {
    [theme.breakpoints.down('sm')]: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },

  textAreaControl: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },

  messageField: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    width: '100%',
    '& .MuiInputBase-multiline': {
      background: 'white',
      border: 0,
      padding: 10,
    },
    [theme.breakpoints.down('xs')]: {
      gridColumnStart: 1,
      gridColumnEnd: 2,
      gridRowStart: 5,
      gridRowEnd: -1,
    },
  },

  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      border: 0,
      borderRadius: 5,
      background: theme.palette.common.white,
      fontSize: 14,

      '&::placeholder': {
        color: theme.palette.grey[500],
        opacity: 0.5,
        [theme.breakpoints.only('xs')]: {
          transform: 'translateY(0)',
        },
      },
    },
  },

  phoneFieldContainer: {
    [theme.breakpoints.only('xs')]: {
      gridColumnStart: '1 !important',
      gridColumnEnd: '3 !important',
    },
  },

  phoneField: {
    border: '0 !important',
    borderRadius: '5px !important',
    background: `${theme.palette.common.white} !important`,
    fontSize: '14px !important',
    height: '15px !important',
  },
  btnSend: {
    fontSize: 16,
    cursor: 'pointer',
    backgroundColor: '#FFA304',
    padding: '10px 25px',
    borderRadius: 5,
    border: 'none',
    color: '#FFFFFF',
    gridColumnStart: 1,
    gridColumnEnd: 3,
    margin: 'auto',
  },
  countryTextField: {
    background: theme.palette.common.white,
    borderRadius: 5,
    height: 35,
    outline: 'none',
    border: 'none',
    '& fieldset': {
      border: 'none',
    },
    '& svg': {
      marginTop: -2,
    },
    '& input': {
      fontSize: 13,
      '&::placeholder': {
        fontSize: 14,
        color: '#000000',
        opacity: 0.5,

        [theme.breakpoints.only('xs')]: {
          transform: 'translateY(0)',
        },
      },
    },

    [theme.breakpoints.only('xs')]: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },

}))

export const ContactForm = () => {
  const classes = useStyles()
  const countries = useGetAllCountries()

  const initialValues: ContactService.ContactInfoRequest = {
    name: '',
    email: '',
    country: null,
    phone_number: '',
    message: '',
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    await ContactService.sendContactInfo({ ...values, country: values.country.id })
    success('Mensaje enviado correctamente')
    setSubmitting(false)
    resetForm()
  }

  const findCountry = (country) => countries.find((c) => (c.id === country))

  return (
    <div className={classes.container}>
      <Typography component="h4" className={classes.title}>
        Contacto
      </Typography>
      <Typography className={classes.subtitle}>
        ¿Tienes preguntas?
      </Typography>
      <Typography className={classes.subtitle}>
        No dudes en contactarnos
      </Typography>
      <div className={classes.info}>
        <div className="email">
          <i className="far fa-envelope" />
          <span>info@fzacademia.com</span>
        </div>
        <a href="https://www.facebook.com/fzacademia/" target="_blank" rel="noreferrer" className="facebook">
          <i className="fab fa-facebook" />
          <span>Escríbenos al facebook</span>
        </a>
      </div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize validationSchema={validationSchema}>
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form className={classes.form}>
            <div className={classes.formControl}>
              <InputText
                name="name"
                value={values.name}
                placeholder="Nombre*"
                className={classes.inputField}
                size="small"
                onChange={handleChange}
              />
              {errors.name && touched.name && <InputErrorMessage message={errors.name as string} />}
            </div>

            <div className={classes.formControl}>
              <InputText
                name="email"
                value={values.email}
                placeholder="Correo*"
                className={classes.inputField}
                size="small"
                onChange={handleChange}
              />
              {errors.email && touched.email && <InputErrorMessage message={errors.email as string} />}
            </div>

            <div className={classes.formControl}>
              {countries && countries.length && (
              <InputAutoComplete
                className={classes.countryTextField}
                placeholder="País*"
                size="small"
                value={values?.country?.id ? findCountry(values.country.id) : findCountry(values.country)}
                handleChange={(value: ICountry) => setFieldValue('country', value)}
                options={countries}
              />
              )}
              {errors.country && touched.country && <InputErrorMessage message={errors.country as string} />}
            </div>

            <div className={classes.formControl}>
              <PhoneInput
                placeholder="Teléfono*"
                containerClassName={classes.phoneFieldContainer}
                className={classes.phoneField}
                name="phone_number"
                value={values.phone_number}
                country={values.country}
                countries={countries}
                handleChange={(phone) => setFieldValue('phone_number', phone)}
              />
              {errors.phone_number && touched.phone_number && <InputErrorMessage message={errors.phone_number as string} />}
            </div>

            <div className={classes.textAreaControl}>
              <InputText
                name="message"
                placeholder="Mensaje*"
                value={values.message}
                className={clsx(classes.inputField, classes.messageField)}
                multiline
                rows={3}
                size="small"
                onChange={handleChange}
              />
              {errors.message && touched.message && <InputErrorMessage message={errors.message as string} />}
            </div>

            <Button type="submit" className={classes.btnSend}>Enviar</Button>
          </Form>
        )}
      </Formik>
    </div>

  )
}
