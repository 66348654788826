import { TextField, TextFieldProps } from '@mui/material'
import React from 'react'

const InputText = (props: TextFieldProps) => (
  <TextField
    {...props}
  />
)

export default InputText
