import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from 'src/components/atoms/Typography'
import { Benefit } from './Benefit'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    overflow: 'hidden !important',
    padding: '80px 40px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '80px 0',
    },
    '&:before': {
      content: '\'\'',
      position: 'absolute',
      width: '15%',
      height: 4,
      backgroundColor: 'rgba(45, 45, 45, 0.5)',
      bottom: 0,
      borderRadius: 5,
      left: '50%',
      transform: 'translate(-50%)',
      [theme.breakpoints.only('sm')]: {
        width: '25%',
      },
      [theme.breakpoints.only('xs')]: {
        width: '35%',
      },
    },
  },
  info: {
    maxWidth: '100vw',
    color: '#2D2D2D',
    padding: '80px 80px 60px',
  },
  title: {
    fontWeight: 'bolder',
    fontSize: 'calc(2em + 1vw)',
    '& p': {
      margin: 0,
    },

  },
  text: {
    textAlign: 'left',
    fontSize: 'calc(.7em + 1vw)',
    fontWeight: 300,
    maxWidth: '60vw',
    '& .motivation': {
      fontWeight: '600',
      margin: 0,
    },
    '& .maintext': {
      margin: '10px 0 10px',
      lineHeight: 'calc(.9em + 1vw) !important',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw',
      fontSize: 'calc(.7em + 1vw)',
    },
  },
  image: {
    backgroundImage: 'url("images/landing/clases_online.jpg")',
    padding: 0,
    justifySelf: 'stretch',
    height: 400,
    maxWidth: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.only('sm')]: {
      height: '250px',
      maxWidth: '100vw',
      backgroundPosition: 'center bottom',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.only('xs')]: {
      height: '100px',
    },
  },
}))

const benefits = [
  {
    image: '/images/landing/benefits/horario-flexible.png',
    alt: 'horario-flexible',
    texts: ['Accede en tu', 'horario preferido'],
  },
  {
    image: '/images/landing/benefits/examenes-y-contenido.png',
    alt: 'examenes-y-contenido',
    texts: ['Exámenes y', 'contenido complementario'],
  },
  {
    image: '/images/landing/benefits/plataforma-virtual.png',
    alt: 'plataforma-virtual',
    texts: ['Plataforma virtual', 'interactiva'],
  },
  {
    image: '/images/landing/benefits/tocar.png',
    alt: 'tocar',
    texts: ['Cursos pregrabados', 'en alta calidad'],
  },
]

export const Benefits = () => {
  const classes = useStyles()
  return (
    <Grid component="section" container justifyContent="space-around" classes={{ root: classes.root }}>
      {
        benefits.map((benefit) => (
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} key={benefit.alt}>
            <Benefit image={benefit.image} alt={benefit.alt} texts={benefit.texts} />
          </Grid>
        ))
      }
    </Grid>
  )
}

export const AboutSection = () => {
  const classes = useStyles()

  return (
    <div id="about">
      <Benefits />
      <Grid classes={{ root: classes.info }} justifyContent="space-between" container>
        <Grid
          item
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <Typography component="h3" classes={{ root: classes.title }}>
            UNA
            <br />
            EXPERIENCIA.
          </Typography>
        </Grid>
        <Grid item xl={9} lg={9} md={8} sm={12} xs={12} classes={{ root: classes.text }}>
          <p className="maintext">
            En nuestra academia, aprender música nunca es un tema aburrido, diviértete con tu instrumento musical favorito,
            nuestros profesores han preparado un buen contenido didáctico, metodológico e interactivo.
          </p>
          <span className="motivation">
            Nunca es tarde para aprender algo que te apasiona.
          </span>
        </Grid>
      </Grid>
      <div className={classes.image} />
    </div>
  )
}
