// @packages
import { FC, ReactNode } from 'react'

// @HOC
import WithAuth from 'src/components/HOC/WithAuth'

// @components
import HeaderPublic from 'src/components/molecules/HeaderPublic'
import HeaderPrivate from 'src/components/molecules/HeaderPrivate/HeaderPrivate'
import { MENU_BLOG, MENU_LANDING } from 'src/config/constants/landing'
import { useRouter } from 'next/router'
import { Footer } from './Footer'

// @store
import { useSelector } from '../../../store/types'

interface Props {
  menu: { url: string, title: string }[]
  children: ReactNode
}

const LandingTemplate: FC<Props> = ({
  children,
}) => {
  const isPublic = useSelector(({ general }) => general.isPublic)
  const router = useRouter()

  return (
    <>
      {isPublic ? <HeaderPublic menu={router.asPath.includes('/blog') ? MENU_BLOG : MENU_LANDING} /> : <HeaderPrivate isLanding />}
      {children}
      <Footer />
    </>
  )
}

export default WithAuth(LandingTemplate, {
  layout: false,
  isAuthRequired: false,
  role: [],
})
