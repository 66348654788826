import Uris from 'src/services/Uris'
import formatUri from 'src/utils/formatUri'
import { findInstrumentBySlug, getAllInstruments, IInstrument } from 'src/services/Instrument'
import { getAllCountries, ICountry } from 'src/services/Country'
import useFetchData from './useFetchData'

export const useGetAllInstruments = (enabled = true): IInstrument[] => {
  const { data } = useFetchData<IInstrument[]>({
    uri: Uris.common.instruments.getAll,
    fetcher: () => getAllInstruments(),
    enabled,
  })
  return data
}

export const useFindInstrumentBySlug = (slug: string):IInstrument => {
  const { data } = useFetchData<IInstrument>({
    uri: formatUri(Uris.common.instruments.find, slug),
    fetcher: () => findInstrumentBySlug(slug),
    enabled: !!slug,
  })
  return data
}

export const useGetAllCountries = (enabled = true): ICountry[] => {
  const { data } = useFetchData<ICountry[]>({
    uri: Uris.common.countries,
    fetcher: () => getAllCountries(),
    enabled,
  })
  return data
}
