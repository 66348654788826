export const useLocalStorage = () => {
  const getLocalStorageItems = (itemKey: string) => localStorage.getItem(itemKey)

  const addItems = (itemKey: string, value: string) => {
    localStorage.setItem(itemKey, value)
  }

  const removeLocalStorageItems = (itemKey: string) => {
    localStorage.removeItem(itemKey)
  }

  return {
    getLocalStorageItems,
    addItems,
    removeLocalStorageItems,
  }
}
