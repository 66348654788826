import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: -1,
  },
  supportUs: {
    backgroundColor: '#FFA304',
    padding: '30px 0',
    '& h3': {
      position: 'relative',
      textAlign: 'center',
      fontFamily: "'Roboto Medium', sans-serif !important",
      fontSize: 'calc(1.5em + 1vw)',
      color: '#FFFFFF',
      paddingBottom: 15,
      margin: 0,
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#FFFFFF',
        height: '4px',
        width: '150px',
        borderRadius: '2px',
      },
    },
  },

  logos: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
    gap: '5px',

    '& .logo:last-child img': {
      [theme.breakpoints.only('xl')]: {
        width: '11vw',
      },
      [theme.breakpoints.only('lg')]: {
        width: '15vw',
      },
      [theme.breakpoints.only('md')]: {
        width: '18vw',
      },
      [theme.breakpoints.only('sm')]: {
        width: '25vw',
      },
      [theme.breakpoints.only('xs')]: {
        width: '25vw',
      },
    },

    '& img': {
      height: 'auto',
      padding: '0 50px',
      [theme.breakpoints.only('xl')]: {
        width: '18vw',
      },
      [theme.breakpoints.only('lg')]: {
        width: '25vw',
      },
      [theme.breakpoints.only('md')]: {
        width: '25vw',
        height: 'auto',
      },
      [theme.breakpoints.only('sm')]: {
        width: '35vw',
      },
      [theme.breakpoints.only('xs')]: {
        width: '30vw',
        padding: '0 10px',
      },
    },
  },

  copyrigth: {
    textAlign: 'center',
    fontSize: 'calc(.5em + 1vw)',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 10px',
    backgroundColor: '#2D2D2D',
    color: '#FFA304',
    '& p': {
      margin: 0,
      padding: 0,
    },
  },

  ssrr: {
    display: 'flex',
    fontSize: '35px',
    fontWeight: 300,
    marginTop: '25px',

    '& .icon': {
      cursor: 'pointer',
      color: '#2D2D2D',
      backgroundColor: '#FFA304',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      margin: '0 10px',
      textDecoration: 'none',
    },

  },

}))

export const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.container}>
      <div className={classes.supportUs}>
        <h3>Nos apoyan</h3>
        <div className={classes.logos}>
          <div className="logo">
            <img src="https://www.fzacademia.com/static/images/landing/support-us/apps.co.png" alt="apps-co" />
          </div>
          <div className="logo">
            <img
              src="https://www.fzacademia.com/static/images/landing/support-us/iNNpulsa-Colombia-1.png"
              alt="innpulsa"
            />
          </div>
          <div className="logo">
            <img src="https://www.fzacademia.com/static/images/landing/support-us/MINTINC.png" alt="mintic" />
          </div>
        </div>
      </div>
      <div className={classes.copyrigth}>
        <p>Copyrigth ® 2020 FZ academia.</p>
        <p> Todos los derechos reservados</p>
        <div className={classes.ssrr}>
          <a href="https://www.instagram.com/fzacademia/" rel="noreferrer" target="_blank" className="icon instagram">
            <i className="fab fa-instagram" />
          </a>
          <a href="https://www.facebook.com/fzacademia/" rel="noreferrer" target="_blank" className="icon facebook">
            <i className="fab fa-facebook-f" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCWVRD_dZ2wnm1Xf_R5G0D8w?sub_confirmation=1"
            target="_blank"
            rel="noreferrer"
            className="icon youtube"
          >
            <i className="fab fa-youtube" />
          </a>
        </div>
      </div>
    </footer>
  )
}
