import React, { FC, useState } from 'react'
import { Autocomplete, Box, TextField } from '@mui/material'
import { ICountry } from 'src/services/Country'

interface Props {
  options: any[]
  value?: any
  error?: any
  helperText?: any
  textFieldClassName?: any
  className?: any
  label?: string
  name?: string
  placeholder?: string
  size?: 'small' | 'medium'
  handleChange?: (value: ICountry | string) => void
}

const InputAutoComplete: FC<Props> = ({
  options,
  name,
  error,
  value,
  label,
  placeholder,
  helperText,
  size,
  textFieldClassName,
  className,
  handleChange,
}) => {
  const [inputValue, setInputValue] = useState('')

  return (
    <Autocomplete
      placeholder={placeholder}
      className={className}
      value={value}
      inputValue={inputValue}
      onChange={(e, newValue: ICountry | string) => handleChange(newValue)}
      onInputChange={(e, newInputvalue) => setInputValue(newInputvalue)}
      options={options}
      autoHighlight
      getOptionLabel={(option) => (value?.name ? option.name : '')}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          className={textFieldClassName}
          label={label}
          placeholder={placeholder}
          name={name ?? 'country'}
          error={error}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  )
}

export default InputAutoComplete
