import { NextPage } from 'next'

import { AboutSection } from 'src/components/Landing/AboutSection'
import { AnywhereSection } from 'src/components/Landing/AnywhereSection'
import { ContactSection } from 'src/components/Landing/ContactSection'
import { CoursesSection } from 'src/components/Landing/CoursesSection'
import { HomeSection } from 'src/components/Landing/HomeSection'
import LandingTemplate from 'src/components/templates/Landing'

import { MENU_LANDING } from 'src/config/constants/landing'

const Home: NextPage = () => (
  <LandingTemplate menu={MENU_LANDING}>
    <HomeSection />
    <AboutSection />
    <CoursesSection />
    <AnywhereSection />
    <ContactSection />
  </LandingTemplate>
)

export default Home
