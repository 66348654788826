// @packages
import React, { FC, useEffect, useState } from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

// @styles
import clsx from 'clsx'
import { ICountry } from 'src/services/Country'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  container: {
    '& .special-label': {
      display: 'none',
    },
  },
  inputPhone: {
    width: '100% !important',
  },
  inputSearch: {
    padding: '10px 0 5px 0 !important',

    '& input': {
      padding: '10px !important',
      width: '96% !important',
    },
  },
  error: {
    color: '#e22434',
    fontSize: '0.75rem',
  },
}))

interface Props {
  value: string
  name: string
  label?: string
  error?: any
  helperText?: any
  country?: any
  countries?: ICountry[]
  className?: string
  containerClassName?: string
  placeholder?: string
  handleChange: (phone: string) => void
}

const PhoneInput: FC<Props> = ({
  value,
  name,
  label,
  error,
  helperText,
  countries,
  country,
  placeholder,
  className,
  containerClassName,
  handleChange,
}) => {
  const classes = useStyles()
  const [phoneValue, setPhoneValue] = useState(value)

  const countryFound = countries && countries.find((c) => (typeof country === 'number' ? c.id === country : c.id === country?.id))
  const callingCodeObject = countryFound?.callingCodes.filter(Boolean)

  // @ts-ignore
  let callingCode = callingCodeObject?.length && countryFound && Object.values(...callingCodeObject)
    .join('')
  callingCode = callingCode ? `+${callingCode}` : ''

  const handleChangePhoneByCountry = () => {
    let phone = `+${value}`
    const callingCodeSize = callingCode.length
    phone = phone.slice(callingCodeSize)
    setPhoneValue(`${callingCode}${phone}`)
  }
  const handleChangePhone = (phone) => {
    handleChange(phone)
    setPhoneValue(phone)
  }
  const handleKeyDown = (e) => {
    const phone = e.target.value
    if (phone.startsWith(callingCode) && phone === callingCode && e.key === 'Backspace') e.preventDefault()
  }

  useEffect(() => {
    if (countries) {
      handleChangePhoneByCountry()
    }
  }, [country, countries])

  return (
    <div className={clsx(classes.container, containerClassName)}>
      <ReactPhoneInput
        country="co"
        specialLabel={label}
        searchPlaceholder="Buscar país"
        value={!country ? '57' : phoneValue}
        enableSearch
        autoFormat
        autocompleteSearch={false}
        disableDropdown
        inputClass={clsx(classes.inputPhone, className)}
        onChange={handleChangePhone}
        onKeyDown={handleKeyDown}
        searchClass={classes.inputSearch}
        placeholder={placeholder}
        inputProps={{
          name,
          autoComplete: 'off',
        }}
      />

      <span className={classes.error}>{error}</span>
      <span className={classes.error}>{helperText}</span>
    </div>
  )
}

export default PhoneInput
